import DateSelectDesktop from './DatesSelects/DateSelectDesktop';
import DateSelectMobile from './DatesSelects/DateSelectMobile';
import {
  addDays,
  eachDayOfInterval,
  isAfter,
  isBefore,
  isSameDay,
  startOfDay,
  subDays,
} from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useImmer } from 'use-immer';
import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import 'react-day-picker/dist/style.css';
import { useToggle } from 'react-use';
import useMobileWidth from '@/hooks/useMobileWidth';

// TODO:
// Merge two desktop and mobile variant together
// You can use popper element and rewrite MUI base styles on mobile devices to show fullscreen

export enum FocusedInput {
  DEP = 'depInput',
  ARR = 'arrInput',
}

interface DatesSelectProps {
  setDepartureDate: (departureDate: string) => void;
  setReturnDepartureDate: (arrivalDate: string) => void;
  depI18nKey?: string;
  arrI18nKey?: string;
  className?: string;
  defaultArrivalDate?: Date;
  defaultDepartureDate?: Date;
  canBeInPast?: boolean;
  handleOverlay?: Dispatch<SetStateAction<boolean>>;
}

const DatesSelect: FC<DatesSelectProps> = ({
  depI18nKey,
  arrI18nKey,
  defaultArrivalDate,
  defaultDepartureDate,
  setDepartureDate,
  setReturnDepartureDate,
  canBeInPast,
  className,
  handleOverlay,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMobileWidth();

  // Open state for desktop version
  const [isOpen, toggleOpen] = useToggle(false);
  // Open state for mobile version
  const [anchorDateEl, setAnchorDateEl] = useState<HTMLElement | null>(null);

  const [focusedInput, setFocusedInput] = useState<FocusedInput | null>(null);
  const { query } = useRouter();

  // We have to use immer in order to correctly use react-day-picker library
  // We need to store current state of daypicker here, and with props setDepartureDate | setReturnDepartureDate we will change parent state

  // Rules are following:
  // index 0 =  departure date
  // index 1 =  arrival date

  const timeZone = 'Europe/Prague';
  const todayInTimeZoneCEST = startOfDay(utcToZonedTime(new Date(), timeZone));
  const tomorrowInTimeZoneCEST = startOfDay(addDays(utcToZonedTime(new Date(), timeZone), 1));

  const [dates, mutateDates] = useImmer<Date[]>([
    defaultDepartureDate
      ? startOfDay(utcToZonedTime(defaultDepartureDate, timeZone))
      : todayInTimeZoneCEST,
    defaultArrivalDate ? startOfDay(utcToZonedTime(defaultArrivalDate, timeZone)) : null,
  ]);

  const betweenDates = useMemo(() => {
    if (!dates[1]) {
      return [];
    }
    const adjustedStartDate = addDays(dates[0], 1);
    const adjustedEndDate = subDays(dates[1], 1);

    // Check if the interval between the adjusted dates is zero
    if (
      isBefore(adjustedEndDate, adjustedStartDate) ||
      isSameDay(adjustedEndDate, adjustedStartDate)
    ) {
      return [];
    }
    return eachDayOfInterval({ start: adjustedStartDate, end: adjustedEndDate });
  }, [dates]);

  const presets = [
    { text: t('tickets.today'), date: todayInTimeZoneCEST },
    { text: t('datepicker.tomorrow'), date: tomorrowInTimeZoneCEST },
  ];

  const toggleWithOverlay = () => {
    if (!isMobile) {
      toggleOpen();
      handleOverlay?.((prev) => !prev);
      return;
    }
    setAnchorDateEl((prev) => (!!prev ? null : prev));
  };

  const handleOpen = (input: FocusedInput) => {
    toggleWithOverlay();
    setFocusedInput(input);
  };

  const getInitialView = (): Date | undefined => {
    if (FocusedInput.DEP === focusedInput) return dates[0];
    if (FocusedInput.ARR === focusedInput) return dates[1] || dates[0];
    return undefined;
  };

  const handleDatesChange = (newDate: Date) => {
    const newDateZoned = utcToZonedTime(newDate, 'Europe/Prague');
    if (focusedInput === FocusedInput.DEP) {
      // to check if departureDate is after arrivalDate
      const isAfterActualArrivalDate = isAfter(newDateZoned, dates[1]);
      // if departureDate is after arrivalDate: clear arrivalDate and set departureDate
      mutateDates((draft) => {
        draft[0] = startOfDay(newDate);
        draft[1] = isAfterActualArrivalDate ? null : draft[1];
      });
    }

    if (focusedInput === FocusedInput.ARR) {
      // Check if the arrival date is the same or after the departure date
      const isSameOrAfterDepartureDate =
        isSameDay(newDateZoned, dates[0]) || isAfter(newDateZoned, dates[0]);

      // If arrivalDate is the same or after departureDate, set arrivalDate, else set to null
      mutateDates((draft) => {
        draft[1] = isSameOrAfterDepartureDate ? newDate : null;
      });
    }
    toggleWithOverlay();
  };

  useEffect(() => {
    // Setting initial dates from query
    mutateDates((draft) => {
      draft[0] = query.departureDate
        ? utcToZonedTime(new Date(query.departureDate as string), 'Europe/Prague')
        : draft[0];
      draft[1] = query.returnDepartureDate
        ? utcToZonedTime(new Date(query.returnDepartureDate as string), 'Europe/Prague')
        : draft[1];
    });
  }, [query]);

  useEffect(() => {
    // We have to do it in useEffect to sync with formik values
    if (dates[0]) setDepartureDate(format(dates[0], 'yyyy-MM-dd', { timeZone }));
    if (dates[1]) setReturnDepartureDate(format(dates[1], 'yyyy-MM-dd', { timeZone }));
  }, [dates]);

  useEffect(() => {
    // Effect to prevent mounting desktop popper on mobile devices and mobile popper on desktop devices
    if (isMobile && isOpen) toggleWithOverlay();
    if (!isMobile && !!anchorDateEl) setAnchorDateEl(null);
  }, [isMobile, isOpen]);

  return (
    <div className={className}>
      <DateSelectDesktop
        isOpen={isOpen}
        toggleWithOverlay={toggleWithOverlay}
        presets={presets}
        dates={dates}
        handleDatesChange={handleDatesChange}
        betweenDates={betweenDates}
        canBeInPast={canBeInPast}
        handleOpen={handleOpen}
        focusedInput={focusedInput}
        mutateDates={mutateDates}
        depI18nKey={depI18nKey}
        arrI18nKey={arrI18nKey}
        getInitialView={getInitialView}
      />
      <DateSelectMobile
        toggleWithOverlay={toggleWithOverlay}
        presets={presets}
        dates={dates}
        handleDatesChange={handleDatesChange}
        betweenDates={betweenDates}
        canBeInPast={canBeInPast}
        handleOpen={handleOpen}
        focusedInput={focusedInput}
        mutateDates={mutateDates}
        depI18nKey={depI18nKey}
        arrI18nKey={arrI18nKey}
        setAnchorDateEl={setAnchorDateEl}
        anchorDateEl={anchorDateEl}
        getInitialView={getInitialView}
      />
    </div>
  );
};

export default DatesSelect;
