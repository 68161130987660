import React from 'react';
import classNames from 'classnames';
import FormatTime from '@/components/atoms/FormatTime';
import { Calendar } from '@/icons';

interface DesktopSearchButtonProps {
  date: Date | null;
  placeholderText: string;
}

const DatesSelectButton: React.FC<DesktopSearchButtonProps> = ({ date, placeholderText }) => (
  <div className="flex items-center gap-1 ">
    <Calendar className="w-3 h-3 sm:w-2.5 sm:h-2.5 fill-neutral-gray2" />
    <div className="text-14">
      <span className={classNames('clickableDiv-placeholder', date ? 'selected' : 'default')}>
        {placeholderText}
      </span>
      <span className="clickableDiv-input">
        {date && <FormatTime date={date} formatType="SHORT_DATE" />}
      </span>
    </div>
  </div>
);

export default DatesSelectButton;
