import React from 'react';
import { formatTime, FormatType } from '@/utils/dateTimeUtils';

interface FormatTimeProps {
  date: Date | string;
  formatType?: FormatType;
}

const FormatTime: React.FC<FormatTimeProps> = ({ date, formatType = 'HOURS_MINUTES' }) => (
  <>{formatTime(date, formatType)}</>
);

export default FormatTime;
