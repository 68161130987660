import { FocusedInput } from '../DatesSelect';
import PopoverHeader from '../PopoverHeader';
import { utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Updater } from 'use-immer';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { DayPicker } from 'react-day-picker';
import classNames from 'classnames';
import { Popover } from '@mui/material';
import { Button } from '@/components/atoms/Button';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import DatesSelectButton from '@/components/search/DatesSelectButton';
import { Clear } from '@/icons';
import { dateLocales } from '@/utils/dateTimeUtils';

interface DateSelectMobileProps {
  toggleWithOverlay: () => void;
  presets: { text: string; date: Date }[];
  dates: Date[];
  handleDatesChange: (arg: Date) => void;
  betweenDates: Date[];
  canBeInPast: boolean;
  handleOpen: (arg: FocusedInput) => void;
  focusedInput: FocusedInput;
  depI18nKey?: string;
  arrI18nKey?: string;
  mutateDates: Updater<Date[]>;
  anchorDateEl: HTMLElement | null;
  setAnchorDateEl: Dispatch<SetStateAction<HTMLElement>>;
  getInitialView: () => Date | undefined;
}

const DateSelectMobile: FC<DateSelectMobileProps> = ({
  toggleWithOverlay,
  presets,
  dates,
  handleDatesChange,
  betweenDates,
  canBeInPast,
  handleOpen,
  focusedInput,
  depI18nKey,
  arrI18nKey,
  mutateDates,
  anchorDateEl,
  setAnchorDateEl,
  getInitialView,
}) => {
  const { locale } = useRouter();
  const { t } = useTranslation();
  const smallDevice = global.window && window.matchMedia('(max-width: 700px)').matches;
  const showTwoMonths = !smallDevice;

  return (
    <>
      <div className="lg:hidden relative flex rounded-sm items-center w-full">
        <ClickableDiv
          className={classNames(
            'relative w-1/2 max-w-full h-12 px-1 flex justify-between rounded-sm bg-neutral-white',
            !!anchorDateEl && focusedInput === FocusedInput.DEP
              ? 'clickableDiv-container-open'
              : 'clickableDiv-container',
          )}
          data-id="departure-date"
          onClick={(e) => {
            handleOpen(FocusedInput.DEP);
            setAnchorDateEl(e.target as HTMLElement);
          }}
        >
          <DatesSelectButton date={dates[0]} placeholderText={t(depI18nKey || 'route.departure')} />
        </ClickableDiv>
        <ClickableDiv
          className={classNames(
            'relative w-1/2 h-12 ml-1 px-1 flex justify-between rounded-sm bg-neutral-white',
            !!anchorDateEl && focusedInput === FocusedInput.ARR
              ? 'clickableDiv-container-open'
              : 'clickableDiv-container',
          )}
          data-id="arrival-date"
          onClick={(e) => {
            handleOpen(FocusedInput.ARR);
            setAnchorDateEl(e.target as HTMLElement);
          }}
        >
          <DatesSelectButton
            date={dates[1]}
            placeholderText={t(arrI18nKey || 'arrivalsDepartures.arrival')}
          />
          {!!dates[1] && (
            <Clear
              className="w-2.5 h-2.5 dates-clear-icon"
              onClick={() => {
                mutateDates((draft) => {
                  draft[1] = null;
                });
                toggleWithOverlay();
              }}
            />
          )}
        </ClickableDiv>
      </div>
      <Popover
        anchorEl={anchorDateEl}
        anchorReference="none"
        transitionDuration={0}
        open={!!anchorDateEl}
        onClose={() => setAnchorDateEl(null)}
        PaperProps={{ className: 'cssreset-popover-paper' }}
      >
        <PopoverHeader
          i18nKey={focusedInput === FocusedInput.DEP ? 'departure' : 'arrivalsDepartures.arrival'}
          handleBack={() => setAnchorDateEl(null)}
        />
        <div className="inline-block searchBox-mob-container">
          <div className="px-3 pb-1">
            {presets.map(({ text, date }) => (
              <Button
                key={text}
                variant="secondary"
                size="small"
                className="mt-1 mr-1"
                type="button"
                onClick={() => handleDatesChange(date)}
              >
                {text}
              </Button>
            ))}
          </div>
          {/* Date-picker (mobile) */}
          <DayPicker
            defaultMonth={getInitialView()}
            locale={dateLocales[locale]}
            modifiersStyles={{
              selected: { backgroundColor: '#00519E' },
              betweenDates: { backgroundColor: '#EFF7FF', borderRadius: 0 },
            }}
            modifiers={{ betweenDates }}
            numberOfMonths={showTwoMonths ? 2 : 1}
            pagedNavigation
            disabled={[
              canBeInPast ? undefined : { before: utcToZonedTime(new Date(), 'Europe/Prague') },
              focusedInput === FocusedInput.ARR && dates[0] && { before: dates[0] },
            ]}
            max={3}
            mode="multiple"
            onDayClick={handleDatesChange}
            selected={dates}
          />
        </div>
      </Popover>
    </>
  );
};

export default DateSelectMobile;
