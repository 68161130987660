import { FocusedInput } from '../DatesSelect';
import { utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Updater } from 'use-immer';
import React, { FC } from 'react';
import { DayPicker } from 'react-day-picker';
import classNames from 'classnames';
import { Button } from '@/components/atoms/Button';
import ClickableDiv from '@/components/atoms/ClickableDiv';
import { SearchBoxPopper } from '@/components/atoms/Popper';
import DatesSelectButton from '@/components/search/DatesSelectButton';
import { Clear } from '@/icons';
import { dateLocales } from '@/utils/dateTimeUtils';

interface DateSelectDesktopProps {
  isOpen: boolean;
  toggleWithOverlay: () => void;
  presets: { text: string; date: Date }[];
  dates: Date[];
  handleDatesChange: (arg: Date) => void;
  betweenDates: Date[];
  canBeInPast: boolean;
  handleOpen: (arg: FocusedInput) => void;
  focusedInput: FocusedInput;
  depI18nKey?: string;
  arrI18nKey?: string;
  mutateDates: Updater<Date[]>;
  getInitialView: () => Date | undefined;
}

const DateSelectDesktop: FC<DateSelectDesktopProps> = ({
  isOpen,
  toggleWithOverlay,
  presets,
  dates,
  handleDatesChange,
  betweenDates,
  canBeInPast,
  handleOpen,
  focusedInput,
  depI18nKey,
  arrI18nKey,
  mutateDates,
  getInitialView,
}) => {
  const { locale } = useRouter();
  const { t } = useTranslation();

  return (
    <div className="sm:hidden relative rounded-sm bg-neutral-white">
      <SearchBoxPopper
        open={isOpen}
        onClose={toggleWithOverlay}
        placement="bottom-start"
        content={
          <div className="p-3 searchBox-mob-container">
            <div>
              {presets.map(({ text, date }) => (
                <Button
                  key={text}
                  type="button"
                  onClick={() => handleDatesChange(date)}
                  variant="secondary"
                  size="small"
                  className="mt-1 mr-1"
                >
                  {text}
                </Button>
              ))}
            </div>
            <hr className="mt-2" />
            {/* Date-picker (desktop) */}
            <DayPicker
              locale={dateLocales[locale]}
              defaultMonth={getInitialView()}
              modifiersStyles={{
                selected: { backgroundColor: '#00519E' },
                betweenDates: { backgroundColor: '#EFF7FF', borderRadius: 0 },
              }}
              modifiers={{ betweenDates }}
              numberOfMonths={2}
              pagedNavigation
              disabled={[
                canBeInPast ? undefined : { before: utcToZonedTime(new Date(), 'Europe/Prague') },
                focusedInput === FocusedInput.ARR && dates[0] && { before: dates[0] },
              ]}
              max={3}
              mode="multiple"
              onDayClick={handleDatesChange}
              selected={dates}
            />
          </div>
        }
      >
        <div className="relative flex rounded-sm items-center">
          <hr className="divider-dates" />
          <ClickableDiv
            onClick={() => handleOpen(FocusedInput.DEP)}
            className={classNames(
              'relative w-40 max-w-full h-12 px-1 flex justify-between rounded-sm bg-neutral-white',
              isOpen && focusedInput === FocusedInput.DEP
                ? 'clickableDiv-container-open'
                : 'clickableDiv-container',
            )}
          >
            <DatesSelectButton
              date={dates[0]}
              data-id="departure-date"
              placeholderText={t(depI18nKey || 'route.departure')}
            />
          </ClickableDiv>

          <ClickableDiv
            onClick={() => handleOpen(FocusedInput.ARR)}
            className={classNames(
              'relative w-36 h-12 px-1 flex justify-between rounded-sm bg-neutral-white',
              isOpen && focusedInput === FocusedInput.ARR
                ? 'clickableDiv-container-open'
                : 'clickableDiv-container',
            )}
          >
            <DatesSelectButton
              date={dates[1]}
              data-id="arrival-date"
              placeholderText={t(arrI18nKey || 'arrivalsDepartures.arrival')}
            />
            {!!dates[1] && (
              <Clear
                className="w-2.5 h-2.5 dates-clear-icon"
                onClick={() => {
                  mutateDates((draft) => {
                    draft[1] = null;
                  });
                  toggleWithOverlay();
                }}
              />
            )}
          </ClickableDiv>
        </div>
      </SearchBoxPopper>
    </div>
  );
};

export default DateSelectDesktop;
